import "./App.css";
import { Home } from "./Pages/Home/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import { Header } from "./Components/Header/Header";
import { Routes, Route } from "react-router-dom";
import { ContactUs } from "./Pages/ContactUs/ContactUs";
import { AboutUs } from "./Pages/AboutUs/AboutUs";
import { Footer } from "./Components/Footer/Footer";
import { Footer2 } from "./Components/Footer2/Footer2";
import { Topbar } from "./Components/Topbar/Topbar";
import { TermsCondition } from "./Pages/Terms&Condition/TermsCondition";
import { Faqs } from "./Pages/FAQsPage/Faqs";
// import { Service } from "./Pages/Service/Service";
import Product1 from "./Pages/Products/Product1/Product1";
import Product2 from "./Pages/Products/Product2/Product2";
import Product3 from "./Pages/Products/Product3/Product3";
import Product4 from "./Pages/Products/Product4/Product4";
import Product5 from "./Pages/Products/Product5/Product5";
// import Product6 from "./Pages/Products/Product6/Product6";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useEffect } from "react";

function App() {

  return (
    <div className="App">
      <Topbar />
      <Header />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/about-us" element={<AboutUs />}></Route>
        <Route path="/terms-conditions" element={<TermsCondition />}></Route>
        <Route path="/faqs" element={<Faqs />}></Route>
        {/* <Route path="/our-services" element={<Service />}></Route> */}
        <Route path="/product-1" element={<Product1 />}></Route>
        <Route path="/product-2" element={<Product2 />}></Route>
        <Route path="/product-3" element={<Product3 />}></Route>
        <Route path="/product-4" element={<Product4 />}></Route>
        <Route path="/product-5" element={<Product5 />}></Route>
        {/* <Route path="/product-6" element={<Product6 />}></Route> */}
      </Routes>
      <Footer />
      <Footer2 />
    </div>
  );
}

export default App;
