import { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../product.css";

const Product5 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-md-12 main-page-background">
      <Row>
        <Col md={7}>
          <h2 className="text-center mb-4">Reverse Osmosis Systems</h2>
          <p className="para-text">
            Reverse osmosis (RO) systems are state-of-the-art water purification
            solutions that remove up to 99% of contaminants, including dissolved
            salts, chlorine, lead, and pesticides, by forcing water through a
            semi-permeable membrane. These systems enhance water taste and odor,
            providing high-quality, safe drinking water for residential,
            commercial, and industrial applications. Featuring multi-stage
            filtration, including pre-filters, the RO membrane, and
            post-filters, RO systems ensure comprehensive purification. Designed
            for energy efficiency and low maintenance, RO systems offer an
            effective and reliable solution for clean and pure water, suitable
            for a wide range of uses.
          </p>

        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
    </div>
  );
};

export default Product5;
