import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./footer.css";
import { FaWhatsapp,  FaInstagram, FaTiktok, FaFacebook } from "react-icons/fa";

export const Footer = () => {
  return (
    <footer className="bg-dark text-light py-4">
      <Container>
        <Row>
          <Col md={12} className="mb-3 mb-md-0">
            <h5>Follow Us</h5>
            <p>Stay connected on social media.</p>
            <div>
              <Link
                target="_blank"
                style={{ margin: "12px" }}
                to="https://api.whatsapp.com/send?phone=16474480580&text=Hello%2C%20I%20am%20interested%20in%20your%20services"
              >
                <FaWhatsapp style={{ color: "green" }} size={24} />
              </Link>
              <Link
                target="_blank"
                style={{ margin: "12px" }}
                to="https://www.facebook.com/share/Rg5UmCodVH5dwrqw/?mibextid=LQQJ4d"
              >
                <FaFacebook style={{ color: "#597bf5" }} size={24} />
              </Link>
              <Link
                target="_blank"
                style={{ margin: "12px" }}
                to="https://www.instagram.com/ritowaterpuresystem?igsh=MXg4MjZlN2Z0MHE0YQ%3D%3D&utm_source=qr"
              >
                <FaInstagram style={{ color: "red" }} size={24} />
              </Link>
              <Link
                target="_blank"
                style={{ margin: "12px" }}
                to="https://www.tiktok.com/@ritowaterpurification?_t=8mcXdxl0JG9&_r=1"
              >
                <FaTiktok
                  style={{
                    color: "black",
                    background: "#dee2e6",
                    width: "24px",
                    padding: "2px",
                    "border-radius": "5px",
                    height: "21px",
                  }}
                />
              </Link>
            </div>
          </Col>
          {/* <Col md={6}>
            <h5>Legal</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/terms-conditions" className="header-links-footer">
                  Terms and Conditions
                </Link>
              </li>
              <li>
                <Link to="/faqs" className="header-links-footer">
                  FAQS
                </Link>
              </li>
            </ul>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};
