import { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../product.css";

const Product2 = () => {
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-md-12 main-page-background">
      <Row>
        <Col md={7}>
          <h2 className="text-center mb-4">Ultra Violet Filtration Systems</h2>
          <p className="para-text">
            {/* <strong>WHAT IS ULTRA VIOLET TRANSMITTANCE (UVT)?</strong> */}
            <br />
            <br />
            Ultra Violet (UV) filtration is a highly effective and
            environmentally friendly water purification method that uses
            ultraviolet light to kill or inactivate harmful microorganisms such
            as bacteria, viruses, and protozoa. This chemical-free process
            disrupts the DNA of these microorganisms, rendering them harmless
            and ensuring safe, clean water without affecting its taste, color,
            or odor. UV filtration is fast, efficient, and requires minimal
            maintenance, making it a reliable choice for those seeking a safe
            and eco-friendly water disinfection solution.
          </p>
        
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
    </div>
  );
};

export default Product2;
