import { useEffect, useState } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BackEndURL } from "../../helpers/helpers";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import "./contactUs.css";

export const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const initialFormData = {
    name: "",
    phone: "",
    email: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    var contact_us_url = `${BackEndURL}v1/contact-us`;
    axios
      .post(contact_us_url, formData)
      .then(function (response) {
        var { data } = response;
        if (data.success === true) {
          toast.success(data.msg);
          setLoading(false);
          setFormData(initialFormData);
        }
      })
      .catch(function (error) {
        toast.error(error.message);
        setLoading(false);
      });
  };

  return (
    <div className="col-md-12 main-page-background">
      {loading && (
        <div className="loader-overlay">
          <div className="d-flex justify-content-center align-items-center loader-container">
            <Spinner
              animation="border"
              role="status"
              className="custom-spinner"
            >
              {/* <span className="sr-only">Loading...</span> */}
            </Spinner>
          </div>
        </div>
      )}
      <Row className={loading ? "blur-content" : ""}>
        <Col md={8}>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <h2 className="text-center">Contact Form</h2>
            <div className="row p-2">
              <div className="col-sm-2">
                <label>Enter Name </label>
                <span>:</span>
              </div>
              <div className="col-sm-8">
                <input
                  type="text"
                  placeholder="Enter Name"
                  name="name"
                  required
                  value={formData.name}
                  onChange={handleChange}
                  className="form-control input-sm"
                />
              </div>
            </div>
            <div className="row p-2">
              <div className="col-sm-2">
                <label>Email Address </label>
                <span>:</span>
              </div>
              <div className="col-sm-8">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  required
                  onChange={handleChange}
                  placeholder="Enter Email Address"
                  className="form-control input-sm"
                />
              </div>
            </div>
            <div className="row p-2">
              <div className="col-sm-2">
                <label>Mobile Number</label>
                <span>:</span>
              </div>
              <div className="col-sm-8">
                <input
                  type="text"
                  name="phone"
                  required
                  placeholder="Enter Mobile Number"
                  className="form-control input-sm"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row p-2">
              <div className="col-sm-2">
                <label>Enter Message</label>
                <span>:</span>
              </div>
              <div className="col-sm-8">
                <textarea
                  rows="5"
                  placeholder="Enter Your Message"
                  name="message"
                  required
                  className="form-control input-sm"
                  value={formData.message}
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div style={{ "margin-top": "10px" }} className="text-center mb-4">
              <button
                className="btn btn-primary-color alert-click"
                disabled={loading}
              >
                Send Message
              </button>
            </div>
          </Form>
        </Col>
        <Col md={4}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1668.0022140758779!2d-79.87065552419526!3d43.69816330443289!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b14d33b6ced61%3A0xd483f29f9d3239dd!2sRito%20Renovation%20Ltd.!5e0!3m2!1sen!2sin!4v1708195358780!5m2!1sen!2sin"
            width={350}
            height={400}
            style={{ border: "0" }}
            title="map"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div style={{ margin: "50px", "text-align": "start" }}>
            <h2 className="fs-4 fw-bold" style={{ "margin-top": "10px" }}>
              Address
            </h2>{" "}
            12139 Mississauga RdCaledon, <br /> ON L7C 1X1 <br />{" "}
            Email:ritowaterpuresystem@gmail.com, <br />
            info@ritowaterpurification.ca
            <br />
            <br />
            <h2 className="fs-4 fw-bold" style={{ "margin-top": "10px" }}>
              Coming Soon At{" "}
              <span>
                <Link
                  class="btn btn-primary"
                  to="https://www.google.com/maps/dir//589+Hanlon+Creek+Boulevard,+Guelph,+ON/@43.4933417,-80.2339713,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x882b84fdef8d1a7d:0xc03279c537b079b8!2m2!1d-80.2313964!2d43.4933378?entry=ttu"
                  target="_blank"
                >
                  Get Direction
                </Link>
              </span>
            </h2>{" "}
            589 Hanlon Creek Boulevard, <br />
            Guelph, ON
          </div>
        </Col>
      </Row>
    </div>
  );
};
