import "./home.css";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export const Home = () => {
  const images = [];
  for (var i = 1; i <= 5; i++) {
    var image_path = process.env.PUBLIC_URL + "/images/company/" + i + ".jpg";
    images.push(image_path);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-md-12 main-page-background">
      <h2 className="text-center">Our Work</h2>
      <div className="row">
        <Carousel>
          {images.length !== 0 &&
            images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  width="100%"
                  height="750px"
                  style={{ "object-fit": "cover" }}
                  alt={image}
                  src={image}
                />
              </Carousel.Item>
            ))}
        </Carousel>
      </div>
      <h2 className="text-center mt-4">Our Products</h2>
      <p className="text-center">
        Choosing the right system is essential to getting the best performance
        and outcomes. It takes significant thought to invest in your water
        quality to make sure the selected system is implemented correctly and
        performs optimally. The effectiveness and level of satisfaction you get
        from your water treatment system are greatly impacted by accurate water
        analysis, system size selection, and proper installation. Errors in
        these domains may result in below-average output and decreased
        contentment.{" "}
      </p>

      <div className="row">
        <div className="row mt-5">
          <div className="col-lg-4 mb-4 col-md-6 rounded-card">
            <Link to="/product-1" className="link-href">
              <div className="cover text-center p-4 shadow-md hover-effect">
                <h2 className="fs-4 fw-bolder mt-4">WATER SOFTENERS</h2>
                <p>WATER SOFTNER SYSTEMS</p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 mb-4 col-md-6 rounded-card">
            <Link to="/product-2" className="link-href">
              <div className="cover text-center p-4 shadow-md hover-effect">
                <h2 className="fs-4 fw-bolder mt-4">UV FILTERS</h2>
                <p>ULTRA VIOLET FILTRATION SYSTEMS</p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 mb-4 col-md-6 rounded-card">
            <Link to="/product-3" className="link-href">
              <div className="cover text-center p-4 shadow-md hover-effect">
                <h2 className="fs-4 fw-bolder mt-4">IRON FILTER SYSTEMS</h2>
                <p>IRON FILTRATION SYSTEMS</p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 mb-4 col-md-6 rounded-card">
            <Link to="/product-4" className="link-href">
              <div className="cover text-center p-4 shadow-md hover-effect">
                <h2 className="fs-4 fw-bolder mt-4">ZEBRA MUSCLE FILTERS</h2>
                <p>ZEBRA MUSCLE FILTRATION SYSTEMS</p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 mb-4 col-md-6 rounded-card">
            <Link to="/product-5" className="link-href">
              <div className="cover text-center p-4 shadow-md hover-effect">
                <h2 className="fs-4 fw-bolder mt-4">REVERSE OSMOSIS</h2>
                <p>REVERSE OSMOSIS SYSTEMS</p>
              </div>
            </Link>
          </div>

          <div className="col-lg-4 mb-4 col-md-6 rounded-card">
            <Link  className="link-href">
              <div className="cover text-center p-4 shadow-md hover-effect">
                <h2 className="fs-4 fw-bolder mt-4">
                  COMMERCIAL & WHOLE HOME RO
                </h2>
                <p>COMMERCIAL & WHOLE HOME REVERSE OSMOSIS</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
