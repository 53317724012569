import { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../product.css";

const Product3 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-md-12 main-page-background">
      <Row>
        <Col md={7}>
          <h2 className="text-center mb-4">Iron Filtration Systems</h2>
          <p className="para-text">
            <br />
            Iron filtration systems are specialized water treatment solutions
            designed to remove excess iron, improving water quality by
            eliminating the metallic taste, unpleasant odor, and reddish-brown
            stains on laundry and fixtures. These systems work by oxidizing
            dissolved iron into an insoluble form, which is then filtered out
            using media like manganese greensand or Birm, followed by a
            backwashing process to maintain effectiveness. Ideal for
            residential, commercial, and industrial applications, iron
            filtration systems protect plumbing, extend the lifespan of
            appliances, and are low-maintenance and eco-friendly, providing
            clean, clear, and iron-free water.
          </p>
          {/* <div className="set-btn mt-4 text-center">
            <button className="btn btn-primary">See PDF</button>
          </div> */}
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
      <Row className="mt-4 p-4 row bg-light-color">
        <Col md={7}>
          <h2 className="text-center mb-4">
            IRON MAX Iron Filter
            <br />
            {/* <span className="text-font-family">
              No Chemicals, Salt, or Chlorine.
            </span> */}
          </h2>
          <p className="para-text">
            The Iron Max filter is a specialized water filtration system
            designed to remove high levels of iron, manganese, and hydrogen
            sulfide, ensuring improved water quality for residential and
            commercial use. Utilizing a powerful oxidation process and advanced
            filtration media such as manganese greensand or catalytic carbon, it
            effectively traps and eliminates contaminants. The system features
            an automatic backwashing mechanism for self-cleaning, high flow rate
            capacity, and eco-friendly operation without additional chemicals.
            Save money and water while enjoying better pressure, increased flow,
            and longer-lasting appliances. The chemical-free filter material
            remains unchanged during the process, ensuring it lasts
            indefinitely. Utilizing oxygen from the surrounding air, eliminates
            additional costs. The replenishing process is primarily powered by
            your system's water pressure, making the Iron Max's annual running
            cost minimal. Benefits include enhanced water taste and odor,
            prevention of rust-colored staining, and protection of plumbing
            systems, making the Iron Max filter a reliable and low-maintenance
            solution for clean, iron-free water.
          </p>
          {/* <div className="set-btn mt-4 text-center"> */}
          {/* <button className="btn btn-primary">See PDF</button>
          </div> */}
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={7}>
          <h2 className="text-center mb-4">
            SIDEKICK™
            <br />
            <span className="text-font-family">
              Single Tank Aeration Filter, with Ozone Complete™
            </span>
          </h2>
          <p className="para-text">
            The Single Tank Aeration Filter with Ozone Complete is an advanced
            water treatment system that combines aeration and ozone technologies
            within a compact, single-tank design. This innovative system
            effectively removes iron, manganese, sulfur, and other contaminants,
            enhancing water taste, odor, and clarity. By introducing air and
            injecting ozone, it oxidizes dissolved impurities and provides
            superior disinfection without the need for additional chemicals,
            making it eco-friendly. The system features automatic backwashing
            for low maintenance and long-lasting filtration media, ensuring
            efficient, cost-effective operation. Ideal for residential and
            commercial use, this filter delivers high-quality, safe, and clean
            water with improved pressure and flow.
          </p>

          <br />
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
    </div>
  );
};

export default Product3;
