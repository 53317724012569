import { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";

export const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-md-12 main-page-background">
      <Row>
        <Col md={4}>
          <Image src={process.env.PUBLIC_URL + "/logo.svg"} fluid />
        </Col>
        <Col md={8}>
          <h2 className="text-center" style={{'padding-bottom':'40px'}}>About our Company</h2>
          <p style={{ textAlign: "initial" }}>
            Rito Water Purification System emerged onto the scene with a bold
            vision and a mission to revolutionize the water industry. Founded on
            January , 2024, by Kuljit and Derrick, the company was born out of a
            shared desire to bring about positive change and innovation in water
            purification.
          </p>
          <p style={{ textAlign: "initial" }}>
            From the outset, Kuljit and Derrick set out to challenge the status
            quo, recognizing the need for a new approach to water purification
            that prioritized both quality and affordability. Armed with
            Derrick's unparalleled expertise and Kuljit's strategic acumen, Rito
            embarked on a journey to disrupt the industry landscape. Their
            venture marked a turning point, as Rito quickly gained recognition
            for its commitment to excellence and its unwavering dedication to
            customer satisfaction. By combining Derrick's technical prowess with
            Kuljit's entrepreneurial spirit, Rito swiftly carved out a niche for
            itself as a leader in bespoke water purification solutions. Driven
            by a passion for innovation, Rito set up its workshop on Mississauga
            road, laying the foundation for in-house manufacturing capabilities
            that would set them apart from competitors. This strategic decision
            not only enabled Rito to maintain stringent quality control but also
            empowered them to deliver tailor-made purification systems that met
            the unique needs of each client. Since its inception, Rito Water
            Purification System has remained steadfast in its mission to
            democratize access to clean water. By offering competitive pricing
            without compromising on quality, Kuljit and Derrick are fulfilling
            their vision of making clean water accessible to all. With every
            installation and service, Rito continues to push the boundaries of
            what's possible in water purification, setting new standards for
            reliability, efficiency, and affordability. As they look towards the
            future, Kuljit and Derrick remain committed to driving positive
            change in the water industry, one purification system at a time.
          </p>
        </Col>
      </Row>
    </div>
  );
};
