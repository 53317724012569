import { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../product.css";

const Product4 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-md-12 main-page-background">
      <Row>
        <Col md={7}>
          <h2 className="text-center mb-4">
            Zebra 2-190 Hi Capacity Twin Filter System
          </h2>
          <p className="para-text">
            Zebra mussel filters are essential for safeguarding water intake
            systems from the invasive zebra mussel species, known for their
            rapid reproduction and clogging capabilities. These advanced filters
            utilize fine mesh screens or specialized media to effectively block
            mussels and their larvae, ensuring smooth and uninterrupted water
            flow. Featuring automated self-cleaning mechanisms and durable
            stainless steel construction, zebra mussel filters provide long-term
            reliability and minimal maintenance. By preventing infestations,
            they help protect native aquatic ecosystems and offer significant
            cost savings by reducing maintenance needs and operational downtime.
            Ideal for water treatment plants, industrial facilities, and power
            plants, zebra mussel filters ensure efficient and eco-friendly water
            management.
          </p>
          {/* <div className="set-btn mt-4 text-center">
            <button className="btn btn-primary">See PDF</button>
          </div> */}
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
    </div>
  );
};

export default Product4;
