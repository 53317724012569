import { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../product.css";

const Product1 = () => {
    useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="col-md-12 main-page-background">
      <Row>
        <Col md={7}>
          <h2 className="text-center mb-4">Water Softener Systems</h2>
          <p className="para-text">
            When chlorine is not a problem, the Aqua Pure system is perfect for
            treating water in rural areas. It efficiently handles iron and
            manganese issues.<br></br>
            <br></br> Featuring the Osmonics Logix 268 valve, this high-capacity
            premium blended ion-exchange resin is Gold Seal certified, compliant
            with NSF/ANSI 44, 61, and 372 criteria, and exceeds California
            requirements.<br></br>
            <br></br> Smart electronics: They eliminate guesswork and the need
            for reprogramming by automatically adjusting to changing water
            demand.
          </p>
          <br />
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
      <Row className="mt-4 p-4 row bg-light-color">
        <Col md={7}>
          <h2 className="text-center mb-4">
            Aqua Pure Pro
            <br />
            {/* <span className="text-font-family">The Logical Choice!</span> */}
          </h2>
          <p className="para-text">
            You Can Feel the Quality of AQUA PURE PRO! A Tasty Difference{" "}
            <br></br>
            <br></br>With the Aqua Pure Pro system, you can save a lot of money
            on fuel and electricity as it lessens the build-up of minerals and
            hard water.<br></br>
            <br></br>
            Enhanced Water Heater Efficiency: With less mineral scaling,
            increase efficiency by 22% to 29%.<br></br>
            <br></br> Prolong the lifespan of washing machines, dishwashers, and
            other indoor and outdoor appliances.
          </p>
          <br />
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={7}>
          <h2 className="text-center mb-4">
            Mega Mix
            <br />
          </h2>
          <p className="para-text">
            <ul>
              <li>
                Whisper-Quiet Regeneration: Operates more quietly than
                conventional softeners.
              </li>
              <li>
                Superior High Flow Control Valve: Designed to meet all household
                soft water demands.
              </li>
              <li>
                Salt-Free Compatibility: Functions with potassium for a
                salt-free solution.
              </li>
              <li>
                Soft Water Brine Refill: Provides cleaner and more efficient
                brining.
              </li>
              <li>
                On-Demand Regeneration: Self-cleans according to your water
                usage.
              </li>
              <li>Safe Operation: Utilizes 12-volt AC for safe usage.</li>{" "}
              <li>
                Reliable Power: Hydro-interruption power cell maintains the time
                of day without the need for batteries.
              </li>
              <li>
                Fully Programmable Cycles: Allows customization of cycles to
                suit your needs.
              </li>
              <li>
                Precision Water Monitoring: Tracks every drop for optimal
                cleaning efficiency.
              </li>
              <li>
                Low Salt Warning: Features a programmable alert for low salt
                levels.
              </li>
            </ul>
          </p>
          <br />

          {/* <div className="set-btn mt-4 text-center">
            <button className="btn btn-primary">See PDF</button>
          </div> */}
          <br />
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
      <Row className="mt-4 p-4 row bg-light-color">
        <Col md={7}>
          <h2 className="text-center mb-4">
            Mega Mix Plus
            <br />
          </h2>
          <p className="para-text">
            Introducing the Mega Mix Plus: A perfectly packaged system
            custom-designed for your needs, with simple step-by-step custom
            programming or selectable pre-programmed settings.
            <ul>
              <li>
                Whisper-Quiet Regeneration: Operates more quietly than
                conventional softeners.
              </li>
              <li>
                Superior High Flow Control Valve: Designed to meet all household
                soft water demands with ease.
              </li>
              <li>
                Salt-Free Compatibility: Functions seamlessly with potassium for
                a salt-free solution.
              </li>
              <li>
                Efficient Soft Water Brine Refill: Provides enhanced brining
                efficiency for cleaner results.
              </li>
              <li>
                On-Demand Regeneration: Self-cleans based on your water usage
                for optimal performance.
              </li>
              <li>
                Reliable Power: Hydro-interruption power cell ensures consistent
                operation without batteries.
              </li>
              <li>
                Fully Programmable Cycles: Customize every cycle to your
                specific requirements.
              </li>
              <li>
                Advanced Multi-Level Programming: Featuring one of the best
                valves available.
              </li>
              <li>
                Precision Water Monitoring: Tracks every drop for optimal
                cleaning efficiency.
              </li>
              <li>
                Programmable Low Salt Warning: Alerts you when salt levels are
                low for continuous efficiency.
              </li>
            </ul>
          </p>
          {/* <div className="set-btn mt-4 text-center">
            <button className="btn btn-primary">See PDF</button>
          </div> */}
          <br />
        </Col>
        <Col md={5}>
          <Image
            className="set-image"
            src={process.env.PUBLIC_URL + "/images/dummy.png"}
            fluid
          />
        </Col>
      </Row>
    </div>
  );
};

export default Product1;
