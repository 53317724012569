import { Container, Row, Col } from "react-bootstrap";

export const Footer2 = () => {
  return (
   <footer className="text-light py-3" style={{ background: "#26a9e0" }}>
      <Container>
        <Row>
          <Col md={8}>
            <div>
              <h5>2024 © All Rights Reserved</h5>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
